import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MUIListItem from '@material-ui/core/ListItem';


const useStyles = makeStyles((theme) => ({
  checkbox: {
    height: '30px',
    width: '30px',
    margin: '0 10px 0 10px',
    boxSizing: 'border-box',
    color: '#595959',
  }
}));


const TechnologyCheckbox = ({ id, label, componentCheckboxes, setComponentCheckboxes }) => {
    const classes = useStyles();
    let checked = componentCheckboxes[label]
    if (checked) { checked = true } else { checked = false }


    const onCheckItem = (e, label) => {
      setComponentCheckboxes({
        ...componentCheckboxes,
        [label]: !componentCheckboxes[label]
      })
    }

    return (
        <MUIListItem>
            <FormControlLabel control={
                <Checkbox
                  onChange={ (e) => onCheckItem(e, label) }
                  checked={ checked }
                  value={ label }
                  color='default'
                  inputProps={{ 'aria-label': { label } }}
                  className={ classes.checkbox } />
            } label={<span style={{ fontSize: '15px' }}>{ label }</span>} />
        </MUIListItem>
    )
};

export default TechnologyCheckbox
