import React from 'react';
import { Avatar } from '@material-ui/core';


const UserInfo = ({ user, photo }) => {

    return (
      <div className='user-info'>
        <Avatar src={ photo } />
        <p className='email'>{ user }</p>
      </div>
    )
};

export default UserInfo
