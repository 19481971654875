import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MUIList from '@material-ui/core/List';
import TechnologyCheckbox from '../atoms/TechnologyCheckbox.js';


const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const ChapterAccordion = ({ title, items, componentCheckboxes, setComponentCheckboxes }) => {
    const classes = useStyles();

    return (
      <Accordion>
        <AccordionSummary
          expandIcon={ <ExpandMoreIcon /> }
          aria-controls='panel2a-content'
          id='panel2a-header'
        >
          <Typography className={ classes.heading }>{ title }</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MUIList>
            {items.map(item =>
              <TechnologyCheckbox
                id={ item.id }
                label={ item.name }
                componentCheckboxes={ componentCheckboxes }
                setComponentCheckboxes={ setComponentCheckboxes }
              key={ item.id } />
            )}
          </MUIList>
        </AccordionDetails>
      </Accordion>
    )
};

export default ChapterAccordion
