import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SaveButton from '../atoms/SaveButton.js';
import Sections from '../organisms/Sections.js';


const useStyles = makeStyles((theme) => ({
  form: {
    width: '60vw'
  },
}));


const SelectionForm = ({ allComponents, componentCheckboxes, setComponentCheckboxes }) => {
  const classes = useStyles();

  return (
    <div className={ classes.form }>
      <Sections
        allComponents={ allComponents }
        componentCheckboxes={ componentCheckboxes }
        setComponentCheckboxes={ setComponentCheckboxes}
      />
      <SaveButton
        allComponents={ allComponents }
        componentCheckboxes={ componentCheckboxes }
      />
    </div>
  );
}

export default SelectionForm
