import React from 'react';
import UserInfo from '../molecules/UserInfo.js';

import { auth } from '../../firebase'


const Header = () => {
    const userID = auth.currentUser.email
    const userPhoto = auth.currentUser.photoURL

    return (
    <div id='header'>
      <UserInfo
        user = { userID }
        photo = { userPhoto }/>
    </div>
    )
};

export default Header
