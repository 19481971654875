import React, { useEffect, useState, useRef } from "react"
import "./App.css"
import Content from "./components/pages/Content.js"
import Header from "./components/organisms/Header.js"
import { CircularProgress } from "@material-ui/core"

import { db, auth, provider } from "./firebase"

const App = () => {
  const [componentCheckboxes, setComponentCheckboxes] = useState({})
  const allComponents = useRef({})

  const getUserData = (userID) => {
    db.collection(process.env.REACT_APP_PEOPLE_WITH_COMPONENTS_COLLECTION)
      .doc(userID)
      .get()
      .then((doc) => {
        let components = {}

        if (doc.exists) {
          doc.data().components.forEach((c) => (components[c["name"]] = true))
        }
        return components
      })
      .then((components) => setComponentCheckboxes(components))
      .catch((error) => console.error("Error getting document: ", error))
  }

  // Hook for user login and user data
  useEffect(() => {
    auth.onAuthStateChanged(() => {
      if (!auth.currentUser) {
        auth.getRedirectResult().then((result) => {
          if (result.user) {
            auth.updateCurrentUser(result.user)
          } else {
            auth.signInWithRedirect(provider)
          }
        })
      }
      getUserData(auth.currentUser.email)
    })
  }, [])

  // Hook to do initial load for all components
  useEffect(() => {
    db.collection(process.env.REACT_APP_TECHRADAR_COMPONENTS_COLLECTION)
      .get()
      .then((snapshot) => {
        snapshot.forEach(async (doc) => {
          allComponents.current[doc.data().chapter] =
            allComponents.current[doc.data().chapter] || []
          allComponents.current[doc.data().chapter].push(doc.data())
        })
      })
  }, [])

  return (
    (auth.currentUser && (
      <app is="app">
        <div id="app">
          <Header />
          <Content
            allComponents={allComponents.current}
            componentCheckboxes={componentCheckboxes}
            setComponentCheckboxes={setComponentCheckboxes}
          />
        </div>
      </app>
    )) || <CircularProgress color="secondary" className="circular-progress" />
  )
}

export default App
