import React from 'react';
import SelectionForm from '../organisms/SelectionForm.js';


const Content = ({ allComponents, componentCheckboxes, setComponentCheckboxes }) => {

  return (
    <div id='content'>
      <SelectionForm
        allComponents={ allComponents }
        componentCheckboxes={ componentCheckboxes }
        setComponentCheckboxes={ setComponentCheckboxes }
      />
    </div>
  );
}

export default Content
