import React from 'react';
import MUIList from '@material-ui/core/List';
import ChapterAccordion from '../molecules/ChapterAccordion.js';


const Sections = ({ allComponents, componentCheckboxes, setComponentCheckboxes }) => {

    return (
      <MUIList>
        {Object.keys(allComponents).map((key) =>
          <ChapterAccordion
            title={ key }
            items={ allComponents[key] }
            componentCheckboxes={ componentCheckboxes }
            setComponentCheckboxes={ setComponentCheckboxes }
          key={ key } />
        )}
      </MUIList>
    )
};

export default Sections
