import React from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { db, auth } from '../../firebase'


const useStyles = makeStyles((theme) => ({
  button: {
    float: 'right',
    background: 'linear-gradient(45deg, #ff913a 30%, #ffb47e 90%)'
  },
}));


const SaveButton = ({ allComponents, componentCheckboxes }) => {
  const classes = useStyles();
  const userID = auth.currentUser.email
  const userName = auth.currentUser.displayName

  const handleClick = (e) => {
    // write to Firestore
    let components = []
    Object.entries(allComponents).forEach(([chapter, items]) => {
      items.forEach((item, i) => {
        if (componentCheckboxes[item.name]) {
          components.push({'id': item.id, 'name': item.name, 'chapter': chapter})
        }
      })
    })
    db.collection(process.env.REACT_APP_PEOPLE_WITH_COMPONENTS_COLLECTION)
      .doc(userID)
      .set({ components: components, name: userName, email: userID })
      .then(() => {
        console.log('Data is updated!')
      })
      .catch((error) => console.error('Error writing document: ', error))
    NotificationManager.success('Update Completed', 'Success', 2000)
  }

  return (
    <div>
      <Button onClick={ handleClick } type='submit' variant='contained' color='primary' className={classes.button}>
        Save
      </Button>
      <NotificationContainer />
    </div>
  );
}

export default SaveButton
